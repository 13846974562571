import React from "react";
import DashboardDataGrid from "../Vendor/Dashboard/DashboardDataGrid";

function VendorPerformance() {

    return (
        <>
            <DashboardDataGrid />
        </>
    )

}

export default VendorPerformance;